export const jpListUserCommunity = {
  OE10alumnus: 'アルムナイ',
  OE10listUser: 'ユーザ一覧',
  OE10listUsers: 'アルムナイ',
  OE10general: '一般',
  OE10situation: '利⽤設定 ',
  OE10participating: '参加中',
  OE10pause: '一時停止',
  OE10unreadChat: '未読があるユーザのみ表示',
  OE10support: '詳細検索',
  OE10workHistory: '職歴ポートフォリオ',
  OE10workHistoryAlumni: '職歴',
  OE10contents: '内容',
  OE10personal: '個人ポートフォリオ',
  OE10personalAlumni: '個人的な活動',
  OE10jobTitle: '役職',
  OE10placeholderTextarea: '入力してください。\n複数指定する場合には改行かカンマ区切りで入力してください。',
  OE10placeholderTextareaAlumni: '半角英数字で入力してください。\n複数指定する場合には改行かカンマ区切りで入力してください。',
  OE10clearData: '検索条件クリア',
  OE10searchThisCondition: '検索する',
  OE10select: '件を選択中',
  OE10selectAll: 'すべて選択',
  OE10numberMatching: '掲載開始⽇',
  OE10userDirect: 'ユーザ\nダイレクト',
  OE10userDirect2: 'ユーザダイレクト',
  OE10titleModal: '一括脱退',
  OE10titleModalCsv: 'CSVダウンロード',
  OE10CsvDownload: 'ダウンロード',
  OE10withdrawn: '脱退させました。',
  OE10withdrawnButton: '脱退させる',
  OE10managementFlag: 'ユーザ管理フラグ',
  OE10searchConditions: '検索方法',
  OE10flagA: '管理フラグA',
  OE10flagB: '管理フラグB',
  OE10flagC: '管理フラグC',
  OE10flagD: '管理フラグD',
  OE10checkedAll: 'チェックした全てのフラグを含む',
  OE10oneChecked: 'チェックしたいずれかのフラグを含む',
  numberActions: 'アクション回数',
  OE10condition1: '条件1',
  OE10condition2: '条件2',
  OE10period: '期間',
  OE10action: 'アクション',
  OE10participationManagement: 'アルムナイ参加管理 ',
  OE10enrollUser: '在籍ユーザ',
  OE10updateFailed: '以下のユーザを一括脱退できませんでした。',
  OE10infoSearch: '会員情報検索',
  OE10flagSearch: '管理フラグ検索',
  OE10actionSearch: 'アクション検索',
  OE10includeSearchCriteria: '検索条件に含める',
  OE10excludeThisUser: '該当ユーザーを除く',
  OE10excludeUserConditions: '該当ユーザを除く',
  OE10excludeSelection: '除外選択',
  OE10corporationOrganizationName: '在籍していた企業名',
  OE30available: '利用可',
  OE30usageSettings: '利用設定',
  OE30title: 'ユーザ詳細更新',
  OE30messageHistory: 'メッセージ送信',
  OE30pointHistory: 'ポイント付与履歴',
  OE30userDetail: 'ユーザ詳細',
  OE30managementInformation: '管理情報',
  OE30additionalItems: '追加項目',
  OE30createAt: '付与日時',
  OE30classification: '区分',
  OE30point: 'ポイント',
  OE30activeInformation: 'アクティブ情報',
  OE30institution: '所属期間',
  OE30regisDate: '初回登録日時',
  OE30termDate: 'サービス規約同意日時',
  OE30profile: 'プロフィールと',
  OE30settings: 'ポートフォリオの公開設定',
  OE30updating: 'プロフィール更新時の',
  OE30timeline: 'タイムライン表示',
  OE30portfolio: 'ポートフォリオ更新時の',
  OE30enrollment: '在籍確認情報',
  OE30nameAtTime: '在籍時氏名（漢字）',
  OE30actionHistory: 'アクション履歴',
  OE30actionHistory2: 'アクション数',
  OE30participantGroup: '参加グループ',
  OE30businessPlan: 'ビジネスプラン座談会',
  OE30product: '新製品開発グループ',
  OE30userManagement: 'ユーザ管理',
  OE30alumniDetails: 'アルムナイ詳細',
  OE30notice: '一時停止にすると該当ユーザはコミニティに入室することができなくなりますが、よろしいですか？',
  OE30numberLikes: 'いいね数',
  OE30numberComments: 'コメント数',
  OE30numberInvitations: '招待数',
  OE30numberChatRemarks: 'チャット発言数',
  OE30numberTopicRemarks: 'トークルーム発言数',
  OE30loginCount: 'ログイン回数',
  OE30consentDate: '（コミュニティ）注意事項同意日時', // 2022/10/21 CW-TrungNT Fix for UAT#4449: OE30 (Ph1-R20221027)
  OE30lastLogin: '最終ログイン日時',
  OE30numberPost: '投稿数',
  OE30titleAlumnai: 'アルムナイユーザ編集',
  OE30titleUpdateComplete: 'アルムナイユーザ更新完了',
  OE30name: 'ユーザ詳細編集',
  OE10titleTab2: 'フラグを付与したユーザを一括で検索することができます。',
  OE10titleTab3: '期間、アクション、回数を指定して該当のユーザを一括で検索することができます。',
  OE10numberOfTime: '回数',
  OE30editAddition: '追加項目編集',
  OE30beforeEdit: '編集前',
  OE30afterEdit: '編集後',
  OE30confirmEditAddition: '追加項目編集確認',
  OE30corporationHistoryNotes: '編集履歴メモ',
  OE30errorEditAdditionalInformation: '追加項目は全て不要になっているため、編集可能な項目がありません。',
}
