export const DEFAULT_MIN_LENGTH = 1
export const DEFAULT_MAX_LENGTH = 256
export const PASSWORD_MAX_LENGTH = 128
export const PASSWORD_MIN_LENGTH = 8
export const DEFAULT_MAX_JP_TEXT = 20
export const MIN_WORD = 1
export const MAX_WORD = 100
export const MAX_LENGTH_INTEGER = 10
export const MAX_VALUE_INTEGER = 9999999999
export const DEFAULT_MAX_LENGTH_TEXTAREA = 2000
export const WAITING_SUBMIT_TIME_OUT = 1000
export const MIN_LENGTH_EMAIL = 6
export const MAX_LENGTH_EMAIL = 256
export const MAX_LENGTH_ADDITIONAL_INFORMATION = 100
export const MAX_UPLOAD_FILE_SIZE_BYTES = 20971520
export const MAX_UPLOAD_FILE_SIZE_MB = 20
export const MAX_LENGTH_TITLE_SEARCH = 30
export const MAX_LENGTH_MESSAGE_TITLE_SEARCH = 200
export const MAX_LENGTH_PERSONAL_TITLE_SEARCH = 20
export const MAX_LENGTH_WORK_POSITION_SEARCH = 100
export const MAX_LENGTH_WORK_DEPARTMENT_SEARCH = 100
export const MAX_LENGTH_USER_ID_SEARCH = 'A1234567890'.length
export const MAX_LENGTH_USER_ID_LIST_SEARCH = 'A1234567890,'.length * 300
export const MAX_LENGTH_CONTENT_SEARCH = 200
export const MAX_LENGTH_POST_CONTENT = 400
export const MAX_LENGTH_USER_NAME_SEARCH = 40
export const MAX_LENGTH_TALKROOM_NAME_SEARCH = 30
export const MAX_LENGTH_CREATOR_SEARCH = 40 //「姓(漢字)+名(漢字)」を結合して検索 (「姓と名」の最高長さは20文字）
export const MAX_MENTION_EXCEEDED = 30
