import { GetterTree } from 'vuex'
import { RootState } from '@/store/store.type'
import {
  ARTICLE_CREATING,
  RESET_FLAG,
  ARTICLE_DETAIL,
  CommunitySiteStateType,
  ARTICLE_COMMENT_LIST,
  ARTICLE_TOTAL_PAGE,
  ARTICLE_LIST,
  ARTICLE_TOTAL_RECORDS,
  ARTICLE_IMAGE_MAPPING,
} from '@/store/modules-community-admin/community-site/index'
import { COMMUNITY_SITE_ADMIN_STORE } from '@/store/constants'

export const getters: GetterTree<CommunitySiteStateType, RootState> = {
  [COMMUNITY_SITE_ADMIN_STORE.GETTERS.GET_ARTICLE]: (state: CommunitySiteStateType): any => state[ARTICLE_CREATING],
  [COMMUNITY_SITE_ADMIN_STORE.GETTERS.RESET_FLAG]: (state: CommunitySiteStateType): any => state[RESET_FLAG],
  [COMMUNITY_SITE_ADMIN_STORE.GETTERS.ARTICLE_DETAIL]: (state: CommunitySiteStateType): any => state[ARTICLE_DETAIL],
  [COMMUNITY_SITE_ADMIN_STORE.GETTERS.COMMENTS]: (state: CommunitySiteStateType): any => state[ARTICLE_COMMENT_LIST],
  [COMMUNITY_SITE_ADMIN_STORE.GETTERS.ARTICLE_LIST]: (state: CommunitySiteStateType): any => state[ARTICLE_LIST],
  [COMMUNITY_SITE_ADMIN_STORE.GETTERS.TOTAL_PAGES]: (state: CommunitySiteStateType): any => state[ARTICLE_TOTAL_PAGE],
  [COMMUNITY_SITE_ADMIN_STORE.GETTERS.TOTAL_RECORDS]: (state: CommunitySiteStateType): any => state[ARTICLE_TOTAL_RECORDS],
  [COMMUNITY_SITE_ADMIN_STORE.GETTERS.IMAGE_MAPPING]: (state: CommunitySiteStateType): any => state[ARTICLE_IMAGE_MAPPING],
}
