export const enCommunitySite = {
  CK10ArticleList: 'Article list',
  CK10PublicStatus: 'Public',
  CK10PrivateStatus: 'Private',
  CK10WriteAnArticle: 'Write an article',
  CK10PublishStatus: 'Publish status',
  CK10NoSearchData: 'No community site articles found.',
  CK16DeleteArticle: 'Delete an article',
  CK16DeleteArticleConfirmMessage: "Delete article '%s'.",
  CK20ArticleCreation: 'Community site new registration',
  CM10ArticleUpdate: 'Community site update',
  CK20ContentTextLimit: '(Up to %s characters)',
  CK20CommunitySiteTag: 'コミュニティサイト記事',
  CK20CurrentNumberOfCharacters: 'Current number of characters: %d',
  CK20AutoPost: 'Automatic Posting',
  CK20AutoPostYes: 'Yes',
  CK20AutoPostNo: 'No',
  CK30CreateArticleSuccessTitle: 'Create a new community site article',
  CK30CreateArticleSuccessDescription: 'Registered.\n' + 'Virus checks for attachments such as images may take time.',
  CK30CreateArticleFailedToastMsg: 'Failed to register community site article.',
  CK20TagDescription1: '*Once you confirm entering the text, it will be added as a tag.',
  CK20TagDescription2: '*You can set up to five tags per post.',
  CK12TotalNumberOfComment: 'See %s other comments',
}
