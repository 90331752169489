import { MutationTree } from 'vuex'
import {
  ARTICLE_COMMENT_LIST,
  ARTICLE_CREATING,
  ARTICLE_DETAIL,
  ARTICLE_IMAGE_MAPPING,
  ARTICLE_LIST,
  ARTICLE_TOTAL_PAGE,
  ARTICLE_TOTAL_RECORDS,
  CommunitySiteArticle,
  CommunitySiteStateType,
  initState,
  RESET_FLAG,
} from '@/store/modules-community-admin/community-site/index'
import { COMMUNITY_SITE_ADMIN_STORE, USER_POST_STORE } from '@/store/constants'
import { UserPostStateTypes } from '@/store/modules/user-post'

export const mutations: MutationTree<CommunitySiteStateType> = {
  [COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.SET_ARTICLE](state: CommunitySiteStateType, payload: CommunitySiteArticle) {
    state[ARTICLE_CREATING] = payload
  },
  [COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.SET_IMAGE_MAPPING](state: CommunitySiteStateType, payload: any) {
    state[ARTICLE_IMAGE_MAPPING] = payload
  },
  [COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.CLEAR_ARTICLE_DATA](state: CommunitySiteStateType) {
    state[ARTICLE_CREATING] = initState[ARTICLE_CREATING]
  },
  [COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.UPDATE_RESET_FLAG](state: CommunitySiteStateType, payload: { flag: boolean }) {
    state[RESET_FLAG] = payload.flag
  },
  [COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.GET_ARTICLE_DETAIL](state: CommunitySiteStateType, payload: any) {
    state[ARTICLE_DETAIL] = payload
  },
  [COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.SET_COMMENT](state: CommunitySiteStateType, payload: { total: number; comments: Array<any> }) {
    state[ARTICLE_COMMENT_LIST] = {
      total: payload.total ?? 0,
      comments: payload.comments ?? [],
    }
  },
  [USER_POST_STORE.MUTATIONS.ADD_COMMENT](state: CommunitySiteStateType, payload: any) {
    state[ARTICLE_COMMENT_LIST] = {
      total: state[ARTICLE_COMMENT_LIST].total + 1,
      comments: [...state[ARTICLE_COMMENT_LIST].comments, payload.data],
    }
  },
  [COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.SET_ARTICLE_LIST](state: CommunitySiteStateType, payload: { records: Array<any>; totalPages: number; totalRecords: number }) {
    state[ARTICLE_LIST] = payload.records
    state[ARTICLE_TOTAL_PAGE] = payload.totalPages
    state[ARTICLE_TOTAL_RECORDS] = payload.totalRecords
  },
  [COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.DELETE_COMMENT](state: CommunitySiteStateType, payload: any) {
    state[ARTICLE_COMMENT_LIST] = {
      total: state[ARTICLE_COMMENT_LIST].total,
      comments: state[ARTICLE_COMMENT_LIST].comments.filter((comment) => {
        return comment.id !== payload.post_id
      }),
    }
  },
  [COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.UPDATE_COMMENT](state: CommunitySiteStateType, payload: any) {
    state[ARTICLE_COMMENT_LIST] = {
      total: state[ARTICLE_COMMENT_LIST].total,
      comments: state[ARTICLE_COMMENT_LIST].comments.map((comment) => {
        if (comment.id === payload.id) {
          return { ...comment, published: payload.published }
        }
        return comment
      }),
    }
  },
  [COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.CLEAR_SELECTED_DATA](state: CommunitySiteStateType) {
    state[ARTICLE_DETAIL] = initState[ARTICLE_DETAIL]
    state[ARTICLE_COMMENT_LIST] = initState[ARTICLE_COMMENT_LIST]
  },
}
