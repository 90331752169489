import { ACCOUNT_STORE } from '../../constants'
import { MutationTree } from 'vuex'
import { AccountStateTypes } from './account.types'

export const mutations: MutationTree<AccountStateTypes> = {
  [ACCOUNT_STORE.MUTATIONS.SET_EDIT_NORMAL_DATA](state: AccountStateTypes, payload: any) {
    state.editNormalData = payload
  },
  [ACCOUNT_STORE.MUTATIONS.SET_EMAIL_DATA](state: AccountStateTypes, payload: any) {
    state.emailData = payload
  },
  [ACCOUNT_STORE.MUTATIONS.SET_PASSWORD_DATA](state: AccountStateTypes, payload: any) {
    state.passwordData = payload
  },

  [ACCOUNT_STORE.MUTATIONS.SET_PROFILE_DETAIL](state: AccountStateTypes, payload: any) {
    Object.assign(state.profileDetail, payload)
  },
  [ACCOUNT_STORE.MUTATIONS.SHOW_MODAL](state: AccountStateTypes, payload: boolean) {
    state.showModal = payload
  },
  [ACCOUNT_STORE.MUTATIONS.SET_POINT_HISTORY](state: AccountStateTypes, payload: any) {
    state.point_history = payload
  },
  [ACCOUNT_STORE.MUTATIONS.SET_USER_WITH_DELETED](state: AccountStateTypes, payload: any) {
    const mention_users = [...payload?.data]
    if (!mention_users.some((item) => item.id === 0)) {
      mention_users.unshift({
        id: 0,
        display_name: payload.operating_name,
        image_url: payload?.operating_image_url,
      })
    }

    state.userWithDeleted = mention_users
  },
}
