import { omit } from 'lodash'
import postService, { GetPostType } from '@/services/post.services'
import userPostService, { UpdateStatusMessage } from '@/services/user-post.services'
import { store } from '@/store'
import { POST_STORE, USER_POST_STORE } from '@/store/constants'
import { ERROR_STATUS_HAS_CHANGED } from '@/constants/index'

type CommitType = {
  commit: any
}

export const actions = {
  async [USER_POST_STORE.ACTIONS.GET_POST]({ commit }: CommitType, { chatroom_id, params }: { chatroom_id: string; params: any }): Promise<void> {
    commit(USER_POST_STORE.MUTATIONS.SET_LOADING_POSTS, true)

    try {
      const res = await userPostService.getUserPosts(chatroom_id, params)
      if (res.data?.success) {
        commit(USER_POST_STORE.MUTATIONS.GET_POST, res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  },

  async [USER_POST_STORE.ACTIONS.GET_OPERATIONAL_POST]({ commit }: CommitType, { chatroom_id, params }: { chatroom_id: string; params: any }): Promise<void> {
    commit(USER_POST_STORE.MUTATIONS.SET_LOADING_POSTS, true)
    try {
      const res = await userPostService.getUserOperationalPosts(chatroom_id, params)
      if (res.data?.success) {
        commit(USER_POST_STORE.MUTATIONS.GET_OPERATIONAL_POST, res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  },

  async [USER_POST_STORE.ACTIONS.CREATE_POST](
    { commit }: CommitType,
    { newPost, community_id, queryData }: { newPost: GetPostType; community_id: number; queryData: any }
  ): Promise<void> {
    try {
      const data = await postService.createPost(newPost, community_id)
      if (data.data?.success) {
        if (queryData.operational_posts) {
          store.dispatch(USER_POST_STORE.ACTIONS.GET_OPERATIONAL_POST, omit(queryData, 'operational_posts'))
        } else {
          store.dispatch(USER_POST_STORE.ACTIONS.GET_POST, omit(queryData, 'operational_posts'))
        }
      }
    } catch (error) {
      console.log(error)
    }
  },

  async [USER_POST_STORE.ACTIONS.UPDATE_POST]({ commit }: CommitType, { updatePost, queryData }: { updatePost: any; queryData: any }): Promise<void> {
    try {
      const data = await userPostService.updateUserPosts(updatePost.get('id'), updatePost)
      if (data.data?.success) {
        commit(USER_POST_STORE.MUTATIONS.UPDATE_POST, data.data.data)
        if (queryData.operational_posts) {
          store.dispatch(USER_POST_STORE.ACTIONS.GET_OPERATIONAL_POST, omit(queryData, 'operational_posts'))
        } else {
          store.dispatch(USER_POST_STORE.ACTIONS.GET_POST, omit(queryData, 'operational_posts'))
        }
      }
    } catch (error) {
      console.log(error)
    }
  },

  async [USER_POST_STORE.ACTIONS.DELETE_POST]({ commit }: CommitType, { id, queryData }: { id: string; queryData: any }): Promise<void> {
    try {
      const data = await userPostService.deleteUserPosts(id)
      if (data.data?.success) {
        if (queryData.operational_posts) {
          store.dispatch(USER_POST_STORE.ACTIONS.GET_OPERATIONAL_POST, omit(queryData, 'operational_posts'))
        } else {
          store.dispatch(USER_POST_STORE.ACTIONS.GET_POST, omit(queryData, 'operational_posts'))
        }
      }
    } catch (error) {
      console.log(error)
    }
  },

  [USER_POST_STORE.ACTIONS.SET_CURRENT_COMMENT]({ commit }: CommitType, payload: any): void {
    commit(USER_POST_STORE.MUTATIONS.SET_CURRENT_COMMENT, payload)
  },

  [USER_POST_STORE.ACTIONS.UPDATE_LIKE_INTEREST_POST]({ commit }: CommitType, payload: any): void {
    commit(USER_POST_STORE.MUTATIONS.UPDATE_LIKE_INTEREST_POST, payload)
  },

  async [USER_POST_STORE.ACTIONS.UPDATE_STATUS_COMMENT]({ commit }: CommitType, payload: UpdateStatusMessage): Promise<any> {
    try {
      const data: any = await userPostService.updateStatusMessage(payload)

      if (data.data?.success) {
        commit(USER_POST_STORE.MUTATIONS.UPDATE_STATUS_COMMENT, data?.data?.data)
        return {
          success: true,
        }
      } else if (data?.response?.data?.data[0]?.message_code === ERROR_STATUS_HAS_CHANGED) {
        const payloadRemoveCommit = {
          postId: payload.post_id,
          postCommentId: payload.comment_id,
        }
        // データが削除されていた場合、storeから削除する
        commit(POST_STORE.MUTATIONS.DELETE_COMMENT, payloadRemoveCommit)
        // コミュニティ管理者用（ステータス変更は管理者しか使えないため、権限チェックは行わない）
        commit(USER_POST_STORE.MUTATIONS.REMOVE_COMMENT, payloadRemoveCommit)
        return {
          success: false,
          message_code: ERROR_STATUS_HAS_CHANGED,
        }
      }
      return {
        success: false,
        message_code: data?.response?.data?.data[0]?.message_code,
      }
    } catch (error) {
      console.log(error)
      return {
        success: false,
        message_code: null,
      }
    }
  },
  [USER_POST_STORE.ACTIONS.SET_SHOW_ALL_COMMENTS]({ commit }: CommitType, payload: any): void {
    commit(USER_POST_STORE.MUTATIONS.SET_SHOW_ALL_COMMENTS, payload)
  },
  //2022/11/28 PhuongTT Fix for UAT#5451 (Ph2-R20221222)
  async [USER_POST_STORE.ACTIONS.SET_LIST_COMMENT_USER_POST]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data = await postService.listComment(payload?.community_id, payload?.params)

      if (data.data?.success) {
        const params = {
          data: data?.data?.data,
          postId: payload?.params.post_id,
        }
        commit(USER_POST_STORE.MUTATIONS.SET_LIST_COMMENT_USER_POST, params)
      }
    } catch (error) {
      console.log(error)
    }
  },

  //2022/11/12 TienNV Fix for UAT#5121: CT10,OF10 (Ph2-R20221116)
  async [USER_POST_STORE.ACTIONS.SET_POST_DETAIL](
    { commit }: CommitType,
    { screen, community_id, postId, from_ct_10 }: { screen: string; community_id: string; postId: string; from_ct_10: boolean }
  ): Promise<void> {
    try {
      const requestParams = from_ct_10 ? { from_ct_10 } : null
      const data =
        screen === 'operationalPost'
          ? await userPostService.detailOperationalPosts(community_id, postId, requestParams)
          : await userPostService.detailUserPosts(community_id, postId)

      if (data.data?.success) {
        commit(USER_POST_STORE.MUTATIONS.SET_POST_DETAIL, data?.data?.data)
      } else {
        commit(USER_POST_STORE.MUTATIONS.SET_POST_DETAIL, 'error')
      }
    } catch (error) {
      console.log(error)
    }
  },
  async [USER_POST_STORE.ACTIONS.REMOVE_POST]({ commit }: CommitType, payload: { postId: string }): Promise<void> {
    commit(USER_POST_STORE.MUTATIONS.REMOVE_POST, payload.postId)
  },
}
