import {
  ARTICLE_AUTO_POST_FIELD_NAME,
  ARTICLE_CONTENT_FIELD_NAME,
  ARTICLE_PUBLISH_STATUS_FIELD_NAME,
  ARTICLE_TAGS_FIELD_NAME,
  ARTICLE_TITLE_FIELD_NAME,
} from '@/views/community-admin/community-site/constants'
import { CommitOptions, DispatchOptions, Module, Store as VuexStore } from 'vuex'
import { COMMUNITY_SITE_ADMIN_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { actions } from './community-site.actions'
import { mutations } from './community-site.mutations'
import { getters } from './community-site.getters'
import { PostType, UserType } from '@/store/modules/post'

export const ARTICLE_CREATING = 'articleCreating'
export const RESET_FLAG = 'resetFlag'
export const ARTICLE_DETAIL = 'articleDetail'
export const ARTICLE_COMMENT_LIST = 'comments'
export const ARTICLE_LIST = 'list'
export const ARTICLE_TOTAL_PAGE = 'page'
export const ARTICLE_TOTAL_RECORDS = 'records'
export const ARTICLE_IMAGE_MAPPING = 'mappings'

export type CommunitySiteStateType = {
  [ARTICLE_LIST]: Array<PostType>
  [ARTICLE_TOTAL_PAGE]: number
  [ARTICLE_TOTAL_RECORDS]: number
  [ARTICLE_CREATING]: CommunitySiteArticle
  [RESET_FLAG]: Boolean
  [ARTICLE_DETAIL]: any
  [ARTICLE_COMMENT_LIST]: ArticleCommentListType
  [ARTICLE_IMAGE_MAPPING]: any
}

export type ArticleCommentListType = {
  comments: Array<CommentType>
  total: number
}

export type CommunitySiteArticle = {
  [ARTICLE_TITLE_FIELD_NAME]?: string
  [ARTICLE_CONTENT_FIELD_NAME]?: string
  [ARTICLE_PUBLISH_STATUS_FIELD_NAME]?: boolean
  [ARTICLE_AUTO_POST_FIELD_NAME]?: number
  [ARTICLE_TAGS_FIELD_NAME]?: Array<ArticleTag>
}

export type ArticleTag = {
  id: string
  name: string
}

export type CommentType = {
  id: string
  post_id: string
  comment: string
  created_at: string
  display_name: string
  image_url: string
  user_type: UserType
}

export interface CommunitySiteAdminActionTypes {
  [COMMUNITY_SITE_ADMIN_STORE.ACTIONS.SET_ARTICLE_DATA](state: CommunitySiteStateType): any
  [COMMUNITY_SITE_ADMIN_STORE.ACTIONS.CLEAR_FORM_ARTICLE_DATA](state: CommunitySiteStateType): any
  [COMMUNITY_SITE_ADMIN_STORE.ACTIONS.GET_ARTICLE_DETAIL](state: CommunitySiteStateType): any
}

export type CommunitySiteAdminMutationTypes<S = CommunitySiteStateType> = {
  [COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.SET_ARTICLE](state: S, payload: CommunitySiteArticle): void
  [COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.GET_ARTICLE_DETAIL](state: S, payload: CommunitySiteArticle): void
}

export interface CommunitySiteAdminGetterTypes {
  [COMMUNITY_SITE_ADMIN_STORE.GETTERS.GET_ARTICLE](state: CommunitySiteStateType): CommunitySiteArticle
  [COMMUNITY_SITE_ADMIN_STORE.GETTERS.ARTICLE_DETAIL](state: CommunitySiteStateType): CommunitySiteArticle
}

export const initState = {
  [ARTICLE_CREATING]: {},
  [RESET_FLAG]: false,
  [ARTICLE_DETAIL]: {},
  [ARTICLE_COMMENT_LIST]: {
    total: 0,
    comments: [],
  },
  [ARTICLE_TOTAL_PAGE]: 0,
  [ARTICLE_TOTAL_RECORDS]: 0,
  [ARTICLE_LIST]: [],
  [ARTICLE_IMAGE_MAPPING]: {},
}

export type CommunitySiteAdminModuleType<S = CommunitySiteStateType> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof CommunitySiteAdminMutationTypes, P extends Parameters<CommunitySiteAdminMutationTypes[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<CommunitySiteAdminMutationTypes[K]>
} & {
  getters: {
    [K in keyof CommunitySiteAdminGetterTypes]: ReturnType<CommunitySiteAdminGetterTypes[K]>
  }
} & {
  dispatch<K extends keyof CommunitySiteAdminActionTypes>(
    key: K,
    payload?: Parameters<CommunitySiteAdminActionTypes[K]>[1],
    options?: DispatchOptions
  ): ReturnType<CommunitySiteAdminActionTypes[K]>
}

const communitySite: Module<CommunitySiteStateType, RootState> = {
  state: initState,
  mutations,
  actions,
  getters,
}

export default communitySite
