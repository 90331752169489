import { translate } from '@/languages/i18n'
import { URL_SUPPORT } from '@/constants/common'
import { URL_ARTICLE_LIST_PATH } from '@/views/community-admin/community-site/constants'

export const ASIDE_MENU = [
  {
    id: 1,
    title: translate('communityHome'),
    icon: require('@/assets/images/home.svg'),
    activeIcon: require('@/assets/images/home-active.svg'),
    to: (route, id) => {
      return `/${route.path.split('/')[1]}/communities/${id}`
    },
    otherRouterActive: (route, params) => {
      return []
    },
  },
  {
    id: 9,
    title: translate('communitySite'),
    icon: require('@/assets/images/journals.svg'),
    activeIcon: require('@/assets/images/journals-active.svg'),

    to: (route, id) => {
      return `/${route.path.split('/')[1]}/communities/${id}/community_site`
    },
    otherRouterActive: (route, params) => {
      return [`/${route.path.split('/')[1]}/communities/${params?.id}/community_site_posts_details/${params?.post_id}`]
    },
  },
  {
    id: 3,
    title: translate('group'),
    icon: require('@/assets/images/chat.svg'),
    activeIcon: require('@/assets/images/chat-active.svg'),

    // to: '/communities/1/groups',
    to: (route, id) => {
      return `/${route.path.split('/')[1]}/communities/${id}/groups`
    },
    otherRouterActive: (route, params) => {
      return []
    },
  },
  {
    id: 2,
    title: translate('chat'),
    icon: require('@/assets/images/group.svg'),
    activeIcon: require('@/assets/images/group-active.svg'),
    // to: '/communities/1/chats',
    to: (route, id) => {
      return `/${route.path.split('/')[1]}/communities/${id}/chats`
    },
    otherRouterActive: (route, params) => {
      return [
        `/${route.path.split('/')[1]}/communities/${params?.id}/posts/${params?.post_id}/chats/${params?.chatroom_id}`,
        `/${route.path.split('/')[1]}/communities/${params?.id}/direct_chat/${params?.chatroom_id}`,
      ]
    },
  },

  {
    id: 4,
    title: translate('message'),
    icon: require('@/assets/images/mail.svg'),
    activeIcon: require('@/assets/images/mail-active.svg'),
    // to: '/communities/1/messages',
    to: (route, id) => {
      return `/${route.path.split('/')[1]}/communities/${id}/messages`
    },
    otherRouterActive: (route, params) => {
      return []
    },
  },
  {
    id: 5,
    title: translate('almunaiAddUser'),
    icon: require('@/assets/images/add-user.svg'),
    activeIcon: require('@/assets/images/add-user-active.svg'),
    to: '',
    otherRouterActive: (route, params) => {
      return []
    },
    openModal: true,
  },
  {
    id: 8,
    title: translate('point'),
    icon: require('@/assets/images/point.svg'),
    activeIcon: require('@/assets/images/point-active.svg'),
    // to: '/yelloop-point',
    to: (route, id) => {
      // 2022/10/04 CW-VietNC Fix for UAT Phase2#4476: SJ10 (Ph2-R202201007)
      return `/${route.path.split('/')[1]}/points?tab=1`
    },
    otherRouterActive: (route, params) => {
      return []
    },
  },
  {
    id: 7,
    title: translate('question'),
    icon: require('@/assets/images/question.svg'),
    activeIcon: require('@/assets/images/question-active.svg'),
    // to: '/faq',
    to: (route, id) => {
      return ``
    },
    otherRouterActive: (route, params) => {
      return []
    },
    targetLink: '_blank',
  },
]

export const MAIN_MENU = [
  {
    id: 1,
    title: translate('top'),
    icon: `<svg id="Component_408_1" data-name="Component 408 – 1" xmlns="http://www.w3.org/2000/svg" width="15.267" height="10.942" viewBox="0 0 15.267 10.942">
    <path id="Path_3417" data-name="Path 3417" d="M169.945,59.011a1.17,1.17,0,1,1,1.111-1.169A1.084,1.084,0,0,1,169.945,59.011Zm10.04-.9a1,1,0,1,0-1,1.048A.973.973,0,0,0,179.985,58.112Zm-.678,1.911a4.528,4.528,0,0,0-1.068-.124h0a4.173,4.173,0,0,0-2.984,1.275l-.313.353,1.674,1.888a1.606,1.606,0,0,1,.288-.683,1.453,1.453,0,0,1,.169-.191,1.666,1.666,0,0,1,1.165-.431h0a1.647,1.647,0,1,1,0,3.295,1.666,1.666,0,0,1-1.167-.432c-.06-.057-1.077-1.215-1.077-1.215l-1.516-1.709h0l-.767-.829a4.122,4.122,0,0,0-3-1.32h0a4.534,4.534,0,0,0-1.066.124,3.888,3.888,0,0,0,0,7.469,4.534,4.534,0,0,0,1.066.124,3.813,3.813,0,0,0,3.008-1.3l-.021.027.31-.349L172.339,64.1a1.6,1.6,0,0,1-.3.7,1.447,1.447,0,0,1-.143.161,1.661,1.661,0,0,1-1.178.443,1.647,1.647,0,1,1,0-3.295h0a1.663,1.663,0,0,1,1.176.441,1.467,1.467,0,0,1,.147.166l3.322,3.744a3.857,3.857,0,0,0,2.878,1.155,4.536,4.536,0,0,0,1.068-.124,3.888,3.888,0,0,0,0-7.468Z" transform="translate(-166.844 -56.674)" fill="#6c757d"/>
  </svg>`,
    to: (route, id) => {
      return `/${route.path.split('/')[1]}/lobby`
    },
  },
  {
    id: 2,
    title: translate('account'),
    icon: `<i class="fas fa-user-circle"></i>`,

    to: (route, id) => {
      return `/${route.path.split('/')[1]}/account/profile/edit`
    },
  },
  {
    id: 5,
    title: translate('point'),
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="18.429" height="21.408" viewBox="0 0 18.429 21.408">
    <g id="Group_2779" data-name="Group 2779" transform="translate(-176.571 -392)">
      <g id="Ellipse_47" data-name="Ellipse 47" transform="translate(177 392)" fill="none" stroke="#444444" stroke-width="1">
        <circle cx="9" cy="9" r="9" stroke="none"/>
        <circle cx="9" cy="9" r="8.5" fill="none"/>
      </g>
      <text id="P" transform="translate(186 406)" fill="#444444" stroke="rgba(0,0,0,0)" stroke-width="1" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700"><tspan x="-3.87" y="0">P</tspan></text>
      <path id="Path_3238" data-name="Path 3238" d="M-21079.416-22335.936v5.377l1.014-1.014,1.146-1.146,2.16,2.16v-5.377Z" transform="translate(29611.848 9212.53) rotate(-30)" fill="#444444"/>
      <path id="Path_3239" data-name="Path 3239" d="M0,5.377V0L1.014,1.014,2.16,2.16,4.32,0V5.377Z" transform="translate(180.313 413.408) rotate(-150)" fill="#444444"/>
    </g>
  </svg>`,
    to: (route, id) => {
      return `/${route.path.split('/')[1]}/points`
    },
  },
  {
    id: 4,
    title: translate('contractInformation'),
    icon: `<i class="fas fa-file-contract"></i> `,
    to: (route, id) => {
      return `/${route.path.split('/')[1]}/communities/${id}/contract`
    },
  },
  {
    id: 3,
    title: translate('support'),
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19">
    <g id="Group_2466" data-name="Group 2466" transform="translate(-177 -428)">
      <g id="Ellipse_46" data-name="Ellipse 46" transform="translate(177 429)" fill="none" stroke="#343a40" stroke-width="1">
        <circle cx="9" cy="9" r="9" stroke="none"/>
        <circle cx="9" cy="9" r="8.5" fill="none"/>
      </g>
      <text id="_" data-name="?" transform="translate(186 442)" fill="#343a40" stroke="rgba(0,0,0,0)" stroke-width="1" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700"><tspan x="-2.985" y="0">?</tspan></text>
    </g>
  </svg>
  `,
    to: () => {
      return ``
    },
  },
  {
    id: 6,
    title: translate('logout'),
    icon: `<i class="fas fa-sign-out-alt"></i>`,
    actionName: 'logout',
    to: (route, id) => {
      return ``
    },
  },
]
export const MAIN_MENU_ADMIN = [
  {
    id: 1,
    title: translate('top'),
    icon: `<svg id="Component_408_1" data-name="Component 408 – 1" xmlns="http://www.w3.org/2000/svg" width="15.267" height="10.942" viewBox="0 0 15.267 10.942">
    <path id="Path_3417" data-name="Path 3417" d="M169.945,59.011a1.17,1.17,0,1,1,1.111-1.169A1.084,1.084,0,0,1,169.945,59.011Zm10.04-.9a1,1,0,1,0-1,1.048A.973.973,0,0,0,179.985,58.112Zm-.678,1.911a4.528,4.528,0,0,0-1.068-.124h0a4.173,4.173,0,0,0-2.984,1.275l-.313.353,1.674,1.888a1.606,1.606,0,0,1,.288-.683,1.453,1.453,0,0,1,.169-.191,1.666,1.666,0,0,1,1.165-.431h0a1.647,1.647,0,1,1,0,3.295,1.666,1.666,0,0,1-1.167-.432c-.06-.057-1.077-1.215-1.077-1.215l-1.516-1.709h0l-.767-.829a4.122,4.122,0,0,0-3-1.32h0a4.534,4.534,0,0,0-1.066.124,3.888,3.888,0,0,0,0,7.469,4.534,4.534,0,0,0,1.066.124,3.813,3.813,0,0,0,3.008-1.3l-.021.027.31-.349L172.339,64.1a1.6,1.6,0,0,1-.3.7,1.447,1.447,0,0,1-.143.161,1.661,1.661,0,0,1-1.178.443,1.647,1.647,0,1,1,0-3.295h0a1.663,1.663,0,0,1,1.176.441,1.467,1.467,0,0,1,.147.166l3.322,3.744a3.857,3.857,0,0,0,2.878,1.155,4.536,4.536,0,0,0,1.068-.124,3.888,3.888,0,0,0,0-7.468Z" transform="translate(-166.844 -56.674)" fill="#6c757d"/>
  </svg>`,
    to: (route, id) => {
      return `/${route.path.split('/')[1]}/lobby`
    },
  },
  {
    id: 4,
    title: translate('contractInformation'),
    icon: `<i class="fas fa-file-contract"></i> `,
    to: (route, id) => {
      return `/${route.path.split('/')[1]}/communities/${id}/contract`
    },
  },
  {
    id: 2,
    title: translate('account'),
    icon: `<i class="fas fa-user-circle"></i>`,

    to: (route, id) => {
      return `/${route.path.split('/')[1]}/account/profile/edit`
    },
  },

  {
    id: 3,
    title: translate('support'),
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19">
    <g id="Group_2466" data-name="Group 2466" transform="translate(-177 -428)">
      <g id="Ellipse_46" data-name="Ellipse 46" transform="translate(177 429)" fill="none" stroke="#343a40" stroke-width="1">
        <circle cx="9" cy="9" r="9" stroke="none"/>
        <circle cx="9" cy="9" r="8.5" fill="none"/>
      </g>
      <text id="_" data-name="?" transform="translate(186 442)" fill="#343a40" stroke="rgba(0,0,0,0)" stroke-width="1" font-size="12" font-family="Roboto-Bold, Roboto" font-weight="700"><tspan x="-2.985" y="0">?</tspan></text>
    </g>
  </svg>
  `,
    to: () => {
      return ``
    },
  },
  {
    id: 6,
    title: translate('logout'),
    icon: `<i class="fas fa-sign-out-alt"></i>`,
    actionName: 'logout',
    to: (route, id) => {
      return ``
    },
  },
]

export const menu_admin = [
  {
    id: 1,
    title: translate('home'),
    icon: require('@/assets/images/home-active.png'),
    activeIcon: require('@/assets/images/home-white.svg'),
    active: false,
    to: (route, id) => {
      return `/${route.path.split('/')[1]}/communities/${id}/dashboard`
    },
  },
  {
    id: 2,
    title: translate('userManagement'),
    icon: require('@/assets/images/person-settings.svg'),
    active: false,
    accordion: true,
    items: [
      {
        name: translate('employeeUser'),
        id: 3,
        to: (route, id) => {
          return `/${route.path.split('/')[1]}/communities/${id}/community-users?management_no=desc`
        },
      },
      {
        id: 4,
        name: translate('almunai'),
        to: (route, id) => {
          return `/${route.path.split('/')[1]}/communities/${id}/alumnus?management_no=desc`
        },
      },
      {
        id: 5,
        name: translate('chatManagement'),
        to: (route, id) => {
          return `/${route.path.split('/')[1]}/communities/${id}/manage_chat_room?tab=recruitment`
        },
      },
    ],
  },

  {
    id: 6,
    title: translate('almunaiParManagement'),
    icon: require('@/assets/images/add-user-active.svg'),
    active: false,
    accordion: true,
    items: [
      {
        id: 7,
        name: translate('waitingApproval'),
        to: (route, id) => {
          return `/${route.path.split('/')[1]}/communities/${id}/alumnus_candidates_waiting_approval?management_no=asc`
        },
      },
      {
        id: 8,
        name: translate('registrationForm'),
        to: (route, id) => {
          return `/${route.path.split('/')[1]}/communities/${id}/alumni_register_form`
        },
      },
      // {
      //   id: 9,
      //   name: translate('candidateList'),
      //   to: (route, id) => {
      //     return `/${route.path.split('/')[1]}/communities/${id}/abc`
      //   },
      // },
    ],
  },

  {
    id: 10,
    title: translate('postManagement'),
    icon: require('@/assets/images/edit_form.svg'),
    active: false,
    to: 'faq',
    accordion: true,
    items: [
      {
        id: 11,
        name: translate('userPost'),
        to: (route, id) => {
          return `/${route.path.split('/')[1]}/communities/${id}/user_posts?published=true&created_at=desc`
        },
      },
      {
        id: 12,
        name: translate('adminUserPost'),
        to: (route, id) => {
          return `/${route.path.split('/')[1]}/communities/${id}/operational_posts?published=true&created_at=desc`
        },
      },
    ],
  },
  {
    id: 24,
    title: translate('communitySiteManagement'),
    icon: require('@/assets/images/journal-text.svg'),
    active: false,
    accordion: true,
    items: [
      {
        id: 25,
        name: translate('list'),
        to: (_, id) => {
          return URL_ARTICLE_LIST_PATH(id)
        },
      },
      {
        id: 26,
        name: translate('configuration'),
        to: (route, id) => {
          return `/${route.path.split('/')[1]}/communities/${id}/setting_community_site`
        },
      },
    ],
  },
  {
    id: 12,
    title: translate('groupManagement'),
    icon: require('@/assets/images/people-settings.svg'),
    active: false,
    accordion: true,
    items: [
      {
        id: 13,
        name: translate('list'),
        to: (route, id) => {
          return `/${route.path.split('/')[1]}/communities/${id}/admin/groups?created_at=asc`
        },
      },
    ],
  },

  {
    id: 14,
    title: translate('messageManagement'),
    icon: require('@/assets/images/mail-active.svg'),
    active: false,
    accordion: true,
    items: [
      {
        id: 15,
        name: translate('messageHistory'),
        to: (route, id) => {
          return `/${route.path.split('/')[1]}/communities/${id}/sent_messages_history?booking_date=desc`
        },
      },
      {
        id: 16,
        name: translate('messageTemplate'),
        to: (route, id) => {
          return `/${route.path.split('/')[1]}/communities/${id}/message_templates?page=1`
        },
      },
    ],
  },

  {
    id: 17,
    title: translate('configuration'),
    icon: require('@/assets/images/settings.svg'),
    active: false,
    accordion: true,
    items: [
      {
        id: 18,
        name: translate('notice'),

        to: (route, id) => {
          // 2022/10/12 VietNC sort CF10 UAT#4425(Ph2-R20221021)
          return `/${route.path.split('/')[1]}/communities/${id}/notifications?publication_start_period=desc`
        },
      },
      {
        id: 19,
        name: translate('community'),
        to: (route, id) => {
          return `/${route.path.split('/')[1]}/communities/${id}/edit`
        },
      },
      {
        id: 20,
        name: translate('adminUser'),
        // to: '/admin/communities/1/managers',
        to: (route, id) => {
          return `/${route.path.split('/')[1]}/communities/${id}/managers?management_no=asc`
        },
      },
      {
        id: 21,
        name: translate('userFlag'),
        // to: '/community/communities/1/user_management_flags',
        to: (route, id) => {
          return `/${route.path.split('/')[1]}/communities/${id}/user_management_flags`
        },
      },
      {
        id: 22,
        name: translate('CD40notes'),
        // to: '/community/communities/1/terms_of_uses/edit',
        to: (route, id) => {
          return `/${route.path.split('/')[1]}/communities/${id}/terms_of_uses/edit?tab=alumni`
        },
      },
    ],
  },
]

export const NAVES: Array<{
  id: number
  title: string
  icon: any
  iconHover: any
  iconActive: any
  query: Object
}> = [
  {
    id: 1,
    title: translate('all'),
    icon: require('@/assets/images/bar-all.svg'),
    iconHover: require('@/assets/images/bar-all-hover.svg'),
    iconActive: require('@/assets/images/bar-all-active.svg'),
    query: {},
  },
  {
    id: 2,
    title: translate('article'),
    icon: require('@/assets/images/pen.svg'),
    iconHover: require('@/assets/images/pen-hover.svg'),
    iconActive: require('@/assets/images/pen-active.svg'),
    query: { content_type: 1 },
  },
  {
    id: 3,
    title: translate('recruitment'),
    icon: require('@/assets/images/recruiment.svg'),
    iconHover: require('@/assets/images/recruiment-hover.svg'),
    iconActive: require('@/assets/images/recruiment-active.svg'),
    query: { content_type: 2 },
  },
  {
    id: 4,
    title: translate('jobOffer'),
    icon: require('@/assets/images/job.svg'),
    iconHover: require('@/assets/images/job-hover.svg'),
    iconActive: require('@/assets/images/job-active.svg'),
    query: { content_type: 3 },
  },

  {
    id: 5,
    title: translate('keep'),
    icon: require('@/assets/images/flag.svg'),
    iconHover: require('@/assets/images/flag-hover.svg'),
    iconActive: require('@/assets/images/flag-active.svg'),
    query: { favorite: true },
  },
  {
    id: 6,
    title: translate('myPost'),
    icon: require('@/assets/images/mypost.svg'),
    iconHover: require('@/assets/images/mypost-hover.svg'),
    iconActive: require('@/assets/images/mypost-active.svg'),
    query: { my_post: true },
  },
]

export type CardType = { id: number; title: string; to: string; icon: string }

export const CARD_MENU: Array<CardType> = [
  {
    id: 1,
    title: translate('editPost'),
    to: '',
    icon: `<i class="far fa-edit"></i>`,
  },
  {
    id: 2,
    title: translate('deletePost'),
    to: '',
    icon: `<i class="far fa-trash-alt"></i>`,
  },
]

export const COMMENT_CARD_MENU: Array<CardType> = [
  {
    id: 1,
    title: translate('deleteComment'),
    to: '',
    icon: `<i class="far fa-trash-alt"></i>`,
  },
]

export const layoutType = {
  SIDEBAR: 'layout-sidebar',
  NO_SIDEBAR: 'layout-no-sidebar',
  SIDEBAR_ADMIN: 'layout-admin',
}

export const TIME_OUT_API = 60000
export const TIME_OUT_CODE = 'ECONNABORTED'
export const TRANSACTION_ERROR = 'TRANSACTION_ERROR'
export const FORBIDDEN_OPERATION = 'FORBIDDEN_OPERATION'
export const ERROR_STATUS_HAS_CHANGED = 'ERROR_STATUS_HAS_CHANGED'
export const RECORD_NOT_FOUND = 'RECORD_NOT_FOUND'
export const ERROR_IP_ADDRESS_NOT_WHITELISTED = 'ERROR_IP_ADDRESS_NOT_WHITELISTED'
export const PATH = {
  community: {
    path: 'community',
    header: 'community-headers',
    pathName: 'community-path',
  },
  alumni: {
    path: 'alumni',
    header: 'alumni-headers',
    pathName: 'alumni-path',
  },
}

export const PUBLIC = 1
export const PRIVATE = 0
export const WAITING_APPROVE = 2

export const apiUrlSubpath = {
  community: 'users',
  alumni: 'alumnus',
}

export const PREFECTURES = [
  {
    id: 1,
    name: translate('hokkaido'),
  },
  {
    id: 2,
    name: translate('aomori'),
  },
  {
    id: 3,
    name: translate('iwate'),
  },
  {
    id: 4,
    name: translate('miyagi'),
  },
  {
    id: 5,
    name: translate('akita'),
  },
  {
    id: 6,
    name: translate('yamagata'),
  },
  {
    id: 7,
    name: translate('fukushima'),
  },
  {
    id: 8,
    name: translate('ibaraki'),
  },
  {
    id: 9,
    name: translate('tochigi'),
  },
  {
    id: 10,
    name: translate('gunma'),
  },
  {
    id: 11,
    name: translate('saitama'),
  },
  {
    id: 12,
    name: translate('chiba'),
  },
  {
    id: 13,
    name: translate('tokyo'),
  },
  {
    id: 14,
    name: translate('kanagawa'),
  },
  {
    id: 15,
    name: translate('niigata'),
  },
  {
    id: 16,
    name: translate('toyama'),
  },
  {
    id: 17,
    name: translate('ishikawa'),
  },
  {
    id: 18,
    name: translate('fukui'),
  },
  {
    id: 19,
    name: translate('yamanashi'),
  },
  {
    id: 20,
    name: translate('nagano'),
  },
  {
    id: 21,
    name: translate('gifu'),
  },
  {
    id: 22,
    name: translate('shizuoka'),
  },
  {
    id: 23,
    name: translate('aichi'),
  },
  {
    id: 24,
    name: translate('mie'),
  },
  {
    id: 25,
    name: translate('shiga'),
  },
  {
    id: 26,
    name: translate('kyoto'),
  },
  {
    id: 27,
    name: translate('osaka'),
  },
  {
    id: 28,
    name: translate('hyogo'),
  },
  {
    id: 29,
    name: translate('nara'),
  },
  {
    id: 30,
    name: translate('wakayama'),
  },
  {
    id: 31,
    name: translate('tottori'),
  },
  {
    id: 32,
    name: translate('shimane'),
  },
  {
    id: 33,
    name: translate('hiroshima'),
  },
  {
    id: 34,
    name: translate('okayama'),
  },
  {
    id: 35,
    name: translate('yamaguchi'),
  },
  {
    id: 36,
    name: translate('tokushima'),
  },
  {
    id: 37,
    name: translate('kagawa'),
  },
  {
    id: 38,
    name: translate('ehime'),
  },
  {
    id: 39,
    name: translate('kochi'),
  },
  {
    id: 40,
    name: translate('fukuoka'),
  },
  {
    id: 41,
    name: translate('saga'),
  },
  {
    id: 42,
    name: translate('nagasaki'),
  },
  {
    id: 43,
    name: translate('kumamoto'),
  },
  {
    id: 44,
    name: translate('oita'),
  },
  {
    id: 45,
    name: translate('miyazaki'),
  },
  {
    id: 46,
    name: translate('kagoshima'),
  },
  {
    id: 47,
    name: translate('okinawa'),
  },
  {
    id: 48,
    name: translate('outsideJapanOption'),
  },
]
export const StatusAlumniAccount = { examinated: 'examinated', leave: 'leave' }
