import { translate } from '@/languages/i18n'
import { minLength, required } from '@vuelidate/validators'
import { DEFAULT_MIN_LENGTH, MAX_LENGTH_TITLE_SEARCH } from '@/constants/number'
import { customMaxLength, editorContentSanitize } from '@/utils'

export const DELETE_CONFIRM_MODAL_ID = 'CK16'
export const DELETE_COMPLETE_MODAL_ID = 'CK17'
export const LIKE_MODAL_ID = 'CK11'
export const ARTICLE_MODAL_ID = 'CK12'
export const CREATE_COMPLETE_MODAL_ID = 'CK31'
export const DELETE_COMMENT_MODAL_ID = 'deleteComment'
export const DELETE_COMMENT_SUCCESS_MODAL_ID = 'deleteCommentSuccess'
export const COMMENT_USER_ALUMNI_MODAL_ID = 'editProfileModal'
export const COMMENT_USER_MODAL_ID = 'profileUserModal'
export const ARTICLE_CONTENT_MAX_LENGTH = 4000
export const ARTICLE_TAG_LIMIT = 5
export const ARTICLE_TITLE_FIELD_NAME = 'title'
export const ARTICLE_CONTENT_FIELD_NAME = 'content'
export const ARTICLE_PUBLISH_STATUS_FIELD_NAME = 'published'
export const ARTICLE_PUBLISHED_PUBLIC_VALUE = true
export const ARTICLE_PUBLISHED_PRIVATE_VALUE = false
export const ARTICLE_AUTO_POST_FIELD_NAME = 'only_community_site'
export const ARTICLE_TAGS_FIELD_NAME = 'tags'
export const VALIDATOR_CONTAIN_NG_WORD = 'ngWord'
export const VALIDATOR_WRONG_FORMAT = 'checkTypeImage'
export const VALIDATOR_IMAGE_SIZE_EXCEED = 'fileSizeValidation'
export const VALIDATOR_EMOJI = 'emoji'
export const VALIDATOR_DUPLICATE_TAG = 'sameTagValidation'
export const VALIDATE_TAG_LIMIT = 'postMaxTagValidation'

export const ARTICLE_SEARCH_PUBLIC_FIELD_NAME = 'public'
export const ARTICLE_SEARCH_PRIVATE_FIELD_NAME = 'private'
export const ARTICLE_SEARCH_PUBLISH_STATUS_PUBLIC_VALUE = '1'
export const ARTICLE_SEARCH_PUBLISH_STATUS_PRIVATE_VALUE = '0'
export const PUBLISH_STATUS_NONE = 'none'

export const AUTO_POST_ENABLE = null
export const AUTO_POST_DISABLE = 1
export const CREATE_DATE_FORMAT = 'YYYY/MM/DD hh:mm:ss'

export const URL_ARTICLE_LIST_PATH = (id: string) => `/community/communities/${id}/community_site/post_list`
export const URL_ARTICLE_CREATE_PATH = (id: string) => `/community/communities/${id}/community_site/new`
export const URL_ARTICLE_CREATE_CONFIRM_PATH = (id: string) => `/community/communities/${id}/community_site/new_confirm`
export const URL_ARTICLE_EDIT_PATH = (id: string, idArticle: string) => `/community/communities/${id}/community_site/${idArticle}/edit`
export const URL_ARTICLE_EDIT_CONFIRM_PATH = (id: string, idArticle: string) => `/community/communities/${id}/community_site/${idArticle}/edit_confirm`

export const EDITOR_ACCEPTED_FILE_EXTENSION = ['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/heif']

export const EDITOR_BLOB_IMAGE_REGEX = /<img\s+src="blob:[^"]+">/g
export const EDITOR_CODE_BLOCK_ICON =
  '<svg viewbox="0 -2 15 18">\n' +
  '  <polyline class="ql-even ql-stroke" points="2.48 2.48 1 3.96 2.48 5.45"/>\n' +
  '  <polyline class="ql-even ql-stroke" points="8.41 2.48 9.9 3.96 8.41 5.45"/>\n' +
  '  <line class="ql-stroke" x1="6.19" y1="1" x2="4.71" y2="6.93"/>\n' +
  '  <polyline class="ql-stroke" points="12.84 3 14 3 14 13 2 13 2 8.43"/>\n' +
  '</svg>'

export enum URL_QUERY {
  TITLE = 'title',
  CONTENT = 'content',
  PUBLIC_ARTICLE = 'public_article',
  PRIVATE_ARTICLE = 'private_article',
  PAGE_SIZE = 'page_of_number',
  PUBLISHED_SORT = 'published_sort',
  PUBLISHED = 'published',
  VIEWER_COUNT = 'community_site_view_count',
  COMMENT_COUNT = 'total_comments',
  LIKE_COUNT = 'total_likes',
  MANAGE_NO = 'management_no',
  CREATOR = 'customer_name',
  CREATE_AT = 'created_at',
}

export enum ARTICLE_CELL_ACTION {
  LIKE,
  COMMENT,
  UPDATE,
  DELETE,
}

export const breadcrumb = (communityId: string, isInCreationPages: ArticleCreateMode | null = null) => {
  const bc = [
    {
      content: translate('home'),
      to: `/community/communities/${communityId}/dashboard`,
      active: false,
    },
    {
      content: translate('communitySiteManagement'),
      to: '',
      active: false,
    },
    {
      content: translate('CK10ArticleList'),
      to: isInCreationPages ? URL_ARTICLE_LIST_PATH(communityId) : '',
      active: !isInCreationPages,
    },
  ]
  if (isInCreationPages) {
    bc.push({
      content: isInCreationPages === ArticleCreateMode.CREATE ? translate('CK20ArticleCreation') : translate('CM10ArticleUpdate'),
      to: ``,
      active: true,
    })
  }
  return bc
}

export const CONTENT_SEARCH_MAX_LENGTH = 400

export const displayColumns = (sortField: string) => [
  // タイトル
  { field: 'title', name: translate('title') },
  // 公開ステータス
  { field: 'published_sort', name: translate('CK10PublishStatus'), sort: sortField === 'published_sort' },
  // 閲覧ユーザ数
  { field: 'community_site_view_count', name: translate('CB10numberPV'), sort: sortField === 'community_site_view_count' },
  // いいね
  { field: 'total_likes', name: translate('like'), sort: sortField === 'total_likes' },
  // コメント
  { field: 'total_comments', name: translate('comment'), sort: sortField === 'total_comments' },
  // 管理No
  { field: 'management_no', name: translate('managementNo'), sort: sortField === 'management_no' },
  // 作成者
  { field: 'customer_name', name: translate('creator'), sort: sortField === 'customer_name' },
  // 投稿日
  { field: 'created_at', name: translate('dateSubmissionUserPost'), sort: sortField === 'created_at' },
  // 編集
  { field: 'edit', name: translate('edit') },
  // 削除
  { field: 'delete', name: translate('delete') },
]

export const allowedSortFields = [
  URL_QUERY.PUBLISHED_SORT,
  URL_QUERY.VIEWER_COUNT,
  URL_QUERY.LIKE_COUNT,
  URL_QUERY.COMMENT_COUNT,
  URL_QUERY.MANAGE_NO,
  URL_QUERY.CREATOR,
  URL_QUERY.CREATE_AT,
]

export const tablePageSizeOptions = [
  { value: '30', label: '30' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
]

/**
 * indicates which item will be displayed in the text editor toolbar
 *
 */
export const rickTextEditorToolbarFunction = [
  [{ font: [] }, { size: ['small', false, 'large', 'huge'] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ header: 1 }, { header: 2 }],
  ['code', 'blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
  [{ direction: 'rtl' }, { align: [] }], // text direction
  ['link', 'image', 'clean'],
]

export const formEntryRules = (imageMapping: any) => {
  return {
    [ARTICLE_TITLE_FIELD_NAME]: {
      required,
      maxLength: (value: string) => customMaxLength(value, MAX_LENGTH_TITLE_SEARCH),
      minLength: minLength(DEFAULT_MIN_LENGTH),
    },
    [ARTICLE_CONTENT_FIELD_NAME]: {
      required: (value: string) => editorContentSanitize(value, imageMapping ?? {}) !== '' && value !== '<p><br></p>',
      maxString: (value: string) => customMaxLength(editorContentSanitize(value ?? '', imageMapping ?? {}), ARTICLE_CONTENT_MAX_LENGTH),
    },
    [ARTICLE_PUBLISH_STATUS_FIELD_NAME]: {
      required,
    },
    [ARTICLE_AUTO_POST_FIELD_NAME]: {},
  }
}

export enum ArticleCreateMode {
  CREATE = 'create',
  EDIT = 'edit',
}
