import axios, { AxiosResponse } from 'axios'

const getPostsAnalytics = (params: any): Promise<AxiosResponse> =>
  axios.get(`/analytics`, {
    params,
  })

const getUserPosts = (community_id: string, params: any): Promise<AxiosResponse> =>
  axios.get(`/communities/${community_id}/user_posts`, {
    params,
  })

const getUserOperationalPosts = (community_id: string, params: any): Promise<AxiosResponse> =>
  axios.get(`/communities/${community_id}/official_posts`, {
    params,
  })

const deleteUserPosts = (post_id: string, from_ct_10: boolean = false): Promise<AxiosResponse> => axios.delete(`/user_posts/${post_id}`, { params: { from_ct_10 } })

const updateUserPosts = (post_id: string, payload): Promise<AxiosResponse> => axios.put(`/user_posts/${post_id}`, payload)

//2022/11/12 TienNV Fix for UAT#5121: CT10,OF10 (Ph2-R20221116)
const detailUserPosts = (community_id: string, post_id: string, from_ct_10?: boolean): Promise<AxiosResponse> =>
  axios.get(`/communities/${community_id}/user_posts/detail/${post_id}`, { params: { from_ct_10 } })

const detailOperationalPosts = (community_id: string, post_id: string, params: any): Promise<AxiosResponse> =>
  axios.get(`/communities/${community_id}/official_posts/detail/${post_id}`, { params })

const updateStatusMessage = async ({ post_id, comment_id, published }: UpdateStatusMessage): Promise<AxiosResponse> =>
  axios.put(`user_posts/${post_id}/comments/${comment_id}`, { published, comment_deleted_flg: false })

// const userPostService = { getUserPosts, deleteUserPosts, updateUserPosts, updateStatusMessage }
//2022/11/12 TienNV Fix for UAT#5121: CT10,OF10 (Ph2-R20221116)
const userPostService = {
  getUserPosts,
  deleteUserPosts,
  updateUserPosts,
  getUserOperationalPosts,
  updateStatusMessage,
  getPostsAnalytics,
  detailUserPosts,
  detailOperationalPosts,
}

export default userPostService

export type UpdateStatusMessage = {
  post_id: string
  comment_id: string
  published: boolean
  deleteFlg: boolean
}
