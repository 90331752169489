export const jpCommunitySite = {
  CK10ArticleList: 'コミュニティサイト記事一覧',
  CK10PublicStatus: '公開中',
  CK10PrivateStatus: '非公開',
  CK10WriteAnArticle: '記事する',
  CK10PublishStatus: '公開ステータス',
  CK10NoSearchData: 'コミュニティサイト記事が見つかりませんでした。',
  CK16DeleteArticle: '記事の削除',
  CK16DeleteArticleConfirmMessage: '記事「%s」を削除します。',
  CK20ArticleCreation: 'コミュニティサイト新規登録',
  CM10ArticleUpdate: 'コミュニティサイト記事編集',
  CK20ContentTextLimit: '（%s文字まで）',
  CK20CommunitySiteTag: 'コミュニティサイト記事',
  CK20CurrentNumberOfCharacters: '現在の文字数%d文字',
  CK20AutoPost: '自動投稿',
  CK20AutoPostYes: '行う',
  CK20AutoPostNo: '行わない',
  CK30CreateArticleSuccessTitle: 'コミュニティサイト記事新規作成',
  CK30CreateArticleSuccessDescription: '登録しました。\n\n' + '画像などの添付ファイルはウィルスチェックに\n時間がかかる場合があります。',
  CK30CreateArticleFailedToastMsg: 'コミュニティサイト記事の登録に失敗しました。',
  CK20TagDescription1: '※文字の入力を確定するとタグとして追加されます。',
  CK20TagDescription2: '※タグは1つの投稿に5つまで設定できます。',
  CK12TotalNumberOfComment: '他%s件のコメントを見る',
}
