import { AUTH_STORE, USER_STORE } from '@/store/constants'
import { store } from '@/store'
import axios from 'axios'
import heic2any from 'heic2any'
import _ from 'lodash'
import { BASE64_IMAGE_REGEX, EDITOR_IGNORE_TEXT, mentionRegexTextInput } from '@/constants/regex'
import { MAX_MENTION_EXCEEDED } from '@/constants/number'
import { SNS_LOGIN_URL, SNS_METHOD, SNS_SITE } from '@/constants/common'
import DOMPurify from 'dompurify'
import { keepContentOnlySanitizeConfig } from '@/constants/validate'

export const generateString = (length: number = 1): string => {
  let result: string = ''
  let characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let charactersLength: number = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const generateAlphabetString = (length: number = 1): string => {
  let result: string = ''
  let characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  let charactersLength: number = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const generateNumericString = (length: number = 1): string => {
  let result: string = ''
  let characters: string = '0123456789'
  let charactersLength: number = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const setHeaders = (header: any): void => {
  axios.defaults.headers.common = header
  store.dispatch(AUTH_STORE.ACTIONS.SET_AUTH_VALUE, true)
}

export const getCookie = (name: string): any => {
  const value = `; ${document.cookie}`
  const parts: any = value.split(`; ${name}=`)
  if (parts.length === 2) return parts?.pop().split(';').shift()
}

export const removeCookie = (name: string): void => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

export const buildFormData = (formData: FormData, data: any, parentKey?: any) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
    })
  } else {
    const value = data == null ? '' : data
    formData.append(parentKey, value)
  }
}

export const buildImgUrl = async (file) => {
  let newFileUrl: any
  let newFile: any
  if (file.type === 'image/heic' || file.name.toLowerCase().includes('.heic')) {
    const conversionResult: any = await heic2any({ blob: file, toType: 'image/jpeg', quality: 0.1 })
    newFile = new File([conversionResult], `test.jpeg`, {
      type: 'image/jpeg',
      lastModified: Date.now(),
    })
    newFileUrl = URL.createObjectURL(conversionResult)
  } else {
    newFileUrl = URL.createObjectURL(file)
    newFile = file
  }

  return {
    newFileUrl,
    newFile,
  }
}

export const urlify = (text): string => {
  const urlRegex = /(https?:\/\/[^\s]+)/g
  return text.replace(urlRegex, (url: string) => {
    return '<a href="' + url + '">' + url + '</a>'
  })
}

/**
 * サロゲートペアなどの文字列を意味のある単位で分割してカウントする。
 * カウント方法
 *   - Intl.Segmenterが使える場合は、Intl.Segmenterを利用する。
 *   - 使えない場合、Lodashの_.size()を利用する。
 * @param text string カウントしたい文字列
 * @returns number 文字数
 */
export const countCharInSurrogatePair = (text: string): number => {
  // @ts-ignore
  if (Intl?.Segmenter) {
    // Intl.Segmenterを使って、書記素単位でカウントする
    // @ts-ignore
    const segmenters = new Intl.Segmenter().segment(text)
    // イテレーターに変換してループして計算する
    const iterators = segmenters[Symbol.iterator]()
    let textCount = 0
    for (const iterator of iterators) {
      textCount++
    }
    return textCount
  }
  return _.size(text)
}

/**
 * Vueldateで利用するカスタムmaxLength。
 * 文字数がmaxLength以下の場合はtrue、超えていたらfalseを返す。
 * @param text string カウントしたい文字列
 * @param maxLength number 最大文字数
 * @returns boolean
 */
export const customMaxLength = (text: string, maxLength: number): boolean => {
  return countCharInSurrogatePair(text) <= maxLength
}

/**
 * Vueldateで利用するカスタムminLength。
 * 文字数がminLength以上の場合はtrue、未満の場合はfalseを返す。
 * @param text string カウントしたい文字列
 * @param minLength number 最小文字数
 * @returns boolean
 */
export const customMinLength = (text: string, minLength: number): boolean => {
  return countCharInSurrogatePair(text) >= minLength
}

/**
 * 第1引数で渡したblobをダウンロードする。
 * window、documentが定義されてなければ何もしない。
 * @param blob new Blob()
 * @param fileName string
 */
export const blobDownload = (blob: Blob, fileName: string) => {
  if (!window || !document) return
  const url = window.URL.createObjectURL(blob)
  const element = document.createElement('a')
  element.href = url
  element.download = fileName
  element.click()
}

export const handleBlurInput = (eve: InputEvent, inputEl: HTMLElement) => {
  // 入力したワードの最終文字が空白だった場合、タグ確定する
  if (eve.data && eve.data[eve.data.length - 1].trim() === '') {
    inputEl.blur()
  }
}

/**
 * Use when two or more modals are stacking each other
 * must change each modal elevation (z-index) so that this can make sure
 * that modal is separate from the others
 */
export const changeModalElevation = (modalId: string, elevation: 'backdrop' | 'higher') => {
  const backdrop = document.getElementsByClassName('modal-backdrop')
  const backdropZIndex = backdrop && _.isArray(Array.from(backdrop)) && Array.from(backdrop).length > 0 ? window.getComputedStyle(backdrop[0]).zIndex ?? '0' : '0'

  const modalPost = document.getElementById(modalId)
  if (modalPost) {
    modalPost.style.zIndex = elevation === 'backdrop' ? backdropZIndex : (parseInt(backdropZIndex) + 5).toString(10)
  }
}

// Helper function to scroll to a given DOMRect position
export const scrollToPosition = (domRect: DOMRect, element: Element | Window) => {
  if (element instanceof HTMLElement) {
    element.scrollTo(domRect.left + element.scrollLeft, domRect.top + element.scrollTop - 250)
  } else {
    window.scrollTo(domRect.left + document.documentElement.scrollLeft, domRect.top + document.documentElement.scrollTop - 250)
  }
}

/**
 * Scroll to the error element
 * @param elementSelector string
 * @param wrapperSelector string
 */
export const scrollToErrors = (elementSelector: string = '.invalid-feedback', wrapperSelector: string = '') => {
  // Try to find the wrapper element if a selector is provided
  if (wrapperSelector) {
    const wrapperElement = document.querySelector(wrapperSelector)
    if (wrapperElement) {
      const domRect = (wrapperElement.querySelector(elementSelector) as HTMLElement)?.getBoundingClientRect()
      if (domRect) {
        scrollToPosition(domRect, wrapperElement)
      }
    }
    return
  }

  const domRect = (document.querySelector(elementSelector) as HTMLElement)?.getBoundingClientRect()
  if (domRect) {
    scrollToPosition(domRect, window)
  }
}

/**
 * Convert additional information stored in BE to visualizer data
 * JSON -> js array
 * or if not JSON -> pure js string
 */
export const getAdditionalInformationDisplayData = (orgString: string) => {
  if (!orgString) {
    return ['']
  }
  let displayData
  try {
    const parse = JSON.parse(orgString)
    displayData = _.isArray(parse) ? parse : []
  } catch (err) {
    displayData = [orgString]
  }
  return displayData
}

export const AlumniIdx = '_ALUMNI_IDX'

export const removeAlumniIdx = (str) => {
  // Check string exist  _ALUMNI_IDX ?
  if (str.includes('_ALUMNI_IDX')) {
    // Remove _ALUMNI_IDX using Regex
    return str.replace(/_ALUMNI_IDX_\w+/g, '')
  }
  return str
}

export const removeAlumniIdxArray = (data) => {
  let parsedData

  try {
    // convert str to array
    parsedData = JSON.parse(data)

    if (!Array.isArray(parsedData)) {
      return data
    }
  } catch (error) {
    return data
  }

  // Remove _ALUMNI_IDX using Regex
  let result = parsedData.map((item) => item.replace(/_ALUMNI_IDX_\d+/, ''))

  return JSON.stringify(result)
}

/**
 * Check limit mention in chat, comment box
 * @param text
 * @param users
 */
export const checkLimitMention = (text: string, users: number[]): boolean => {
  const mentionedUserIds = Array.from(text.matchAll(mentionRegexTextInput), (match) => Number(match[1])).filter((userId) => users.includes(userId))

  return mentionedUserIds.length <= MAX_MENTION_EXCEEDED
}

/**
 * Check and get mention user not exists in text chat, comment
 * @param text
 * @param users
 */
// export const checkMentionUserNotExists = (text: string, users: any): boolean => {
//   const mentionRegex = /(?<=\s|^)@\{(\d+)\}(?=\s|$)/g
//   //Get all userIds mentioned in the text
//   const mentionedUserIds = Array.from(text.matchAll(mentionRegex), (match) => Number(match[1]))
//
//   // Find the first userId not in the users list
//   return mentionedUserIds.some((userId) => !users.some((user) => user.id === userId))
// };
export interface SnsConnectPayload {
  userId?: any
  inviteCode?: any
}

export const openSnsConnectUrl = (snsSite: SNS_SITE, method: SNS_METHOD = SNS_METHOD.LOGIN, payload: SnsConnectPayload = {}) => {
  const loginUrlParams = {
    method,
  }
  if (method === SNS_METHOD.CONNECT) {
    const { userId } = payload
    loginUrlParams['user_id'] = userId ?? ''

    const alumniCookieObj = getCookie('alumni-headers')
    if (alumniCookieObj) {
      try {
        const { client } = JSON.parse(alumniCookieObj)
        loginUrlParams['client'] = client
      } catch (parseException) {
        console.log('err', parseException)
      }
    }
  } else if (method === SNS_METHOD.INVITE) {
    loginUrlParams['invited_code'] = payload.inviteCode ?? ''
  }

  const snsLoginUrl = new URL(SNS_LOGIN_URL(snsSite))
  Object.keys(loginUrlParams).forEach((key: string) => {
    snsLoginUrl.searchParams.append(key, loginUrlParams[key])
  })

  // console.log('url', snsLoginUrl.toString())
  window.open(snsLoginUrl.toString(), '_self')
}

export const editorContentSanitize = (rawContent: string, imageMapping) => {
  let newContent = rawContent ?? ''
  if (BASE64_IMAGE_REGEX.test(newContent)) {
    newContent = newContent.replace(BASE64_IMAGE_REGEX, '')
  }

  EDITOR_IGNORE_TEXT.forEach((text) => {
    newContent = newContent.replaceAll(text, '')
  })

  Object.keys(imageMapping).forEach((key) => {
    newContent = newContent.replace(key.replaceAll(/&/g, '&amp;'), imageMapping[key])
  })

  const emptyContentCheck = DOMPurify.sanitize(newContent, keepContentOnlySanitizeConfig) ?? ''
  if (emptyContentCheck.trim().length === 0) return ''

  return newContent ?? ''
}

declare namespace Intl {
  class Segmenter {
    constructor(locale: string, options?: { granularity: 'grapheme' | 'word' | 'sentence' })
    segment(input: string): IterableIterator<{ segment: string; index: number; isWordLike: boolean }>
  }
}

const getUnicodeLength = (str: string): number => {
  const segmenter = new Intl.Segmenter('ja', { granularity: 'grapheme' })
  const graphemes = [...segmenter.segment(str)]
  return graphemes.length
}

/**
 * Vueldateで利用するカスタムmaxLength。
 * 文字数がmaxLength以下の場合はtrue、超えていたらfalseを返す。
 * @param text string カウントしたい文字列
 * @param maxLength number 最大文字数
 * @param mention_user_ids
 * @param users
 * @returns boolean
 */
export const customMaxLengthTextMention = (text: string, maxLength: number, mention_user_ids: any, users: any): boolean => {
  let processedValue = text
  mention_user_ids.forEach((userId) => {
    const user = users.find((user) => user.id === userId)
    if (user) {
      processedValue = processedValue.replace(`@{${userId}}`, `@${user.display_name}`)
    }
  })
  return getUnicodeLength(processedValue) <= maxLength
}
