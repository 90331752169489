import axios, { AxiosResponse } from 'axios'
import { CommunitySiteArticle } from '@/store/modules-community-admin/community-site'

const getArticleList = async (idCommunity: string, params: any): Promise<AxiosResponse> => {
  return axios.get(`/communities/${idCommunity}/community_site_posts`, { params })
}

const createArticle = async (data: CommunitySiteArticle): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ data: null, status: 200, statusText: '200', headers: {}, config: {} })
      // reject('')
    }, 3000)
  })
}

const getArticle = async (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: {
          title: 'Hallo',
          content: '<strong>Mtfk</strong>',
          auto_post: '0',
          publish_status: '1',
        },
        status: 200,
        statusText: '200',
        headers: {},
        config: {},
      })
      // reject('')
    }, 3000)
  })
}

const uploadImage = async (idCommunity: string, data: FormData): Promise<AxiosResponse> => {
  return axios.post(`communities/${idCommunity}/media/upload`, data)
}

const communitySiteAdminServices = {
  getArticleList,
  createArticle,
  getArticle,
  uploadImage,
}

export default communitySiteAdminServices
